<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="600px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="预警天数" prop="yuJingSJ" class="line">
                <el-input v-model="form.yuJingSJ" size="small"  maxlength="36" placeholder="请输入距离过期日期预警的天数" show-word-limit />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {yuJing, update, getUpdate} from "@/service/mdgl/MenDianXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XUploader from "@/components/x/upload/XUploader";
    import CityPicker from "@/components/CityPicker/index";
    import MenDianHangYeSelector from "@/view/selector/MenDianHangYeSelector";

    export default {
        components: {CityPicker, XUploader,MenDianHangYeSelector},
        mixins: [XEditBase],
        beforeCreate() {
            let {required,digits} = ruleBuilder;
            this.rules = {
                yuJingSJ: [required(),digits()], //
            }
            this.titlePrefix = '预警时间';
            this.defaultForm = {
                id: null,
                yuJingSJ: "", //
            };
            this.addService = yuJing;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
    }
</script>

<style scoped>

</style>
